import 'jquery.cookie';
$(function() {
  var cookie = $.cookie('cookiesAccept');
  if(cookie != '0'){
    $('#cookies_accept').show();
  }
  $('#cookies_accept a#accept').click(function() {
    $.cookie("cookiesAccept", "0", { expires: 365 , path: "/"});
    $('#cookies_accept').fadeOut("slow");
  });
});